.App {
  height: 100vh;
  background-color: #0d1116;
  overflow: scroll;
  text-align: center;
}

.container {
  height: 100%;
  background-color: #0d1116;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header-container {
  padding-top: 30px;
}

.header {
  margin: 0;
  font-size: 50px;
  font-weight: bold;
}

a {
  color: #ec38bc;
  text-decoration: none;
};

a:hover {
  text-decoration: underline;
}

.sub-text {
  font-size: 25px;
  color: white;
}

.gradient-text {
  background: linear-gradient(to left, rgb(115, 3, 192), rgb(236, 56, 188), rgb(224, 93, 16));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.cta-button {
  height: 45px;
  border: 0;
  width: auto;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  color: white;
}

.connect-wallet-button {
  background: linear-gradient(to left, rgb(40, 40, 40), rgb(115, 3, 192), rgb(236, 56, 188), rgb(224, 93, 16));
  background-size: 200% 200%;
  animation: gradient-animation 4s ease infinite;
}

.mint-button {
  background: -webkit-linear-gradient(left, #a200d6, #ff6fdf);
  background-size: 200% 200%;
  animation: gradient-animation 4s ease infinite;
  margin-right: 15px;
}

.opensea-button {
  background-color: rgb(32, 129, 226);
}

.mint-count {
  color: white;
  font-size: 18px;
  font-weight: bold;
}

.footer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
}

.twitter-logo {
  width: 35px;
  height: 35px;
}

.footer-text {
  color: white;
  font-size: 16px;
  font-weight: bold;
}

/* KeyFrames */
@-webkit-keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.svgContainer img{
  margin-top: 2em;
}

.loading {
  margin: 0 auto;
  width: 150px;
}

.warning {
  color: white;
  font-weight: lighter;
}

.connected {
  color: #ec38bc;
}

.disconnected {
  color:rgb(221, 220, 220)
}

.connected, .disconnected {
  margin-left: 0.7em;
}

.connectionStatus {
  /* border-radius: 5px; */
  max-width: 250px;
  border: 1px solid #ec38bc;
  border-left: 4px solid #ec38bc;
  padding: 0.5em 1em;
  position: fixed;
  top: 1em;
  right: 50px;
  font-size: 14px;
  color: white;
  text-align: left;
}

.emoji-warning {
  font-size: 1.2em;
  color: white;
}
